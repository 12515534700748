export default {
  methods: {
    formatCurrency: function(val) {
      if (val !== null && !isNaN(val)) return `${val.toFixed(2).replace('.', ',')} €`
      return ''
    },
    formatDate: function(date, showTime) {
      date = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
      let dateString = `${date.substr(8, 2)}.${date.substr(5, 2)}.${date.substr(0, 4)}`
      if (showTime) dateString += ` ${date.substr(11, 5)}`
      return dateString
    },
    formatResidentName: function(options) {
      const { resident } = options
      const salutationType = typeof options.salutationType !== 'undefined' ? options.salutationType : 'long'
      const showFirstName = typeof options.showFirstName !== 'undefined' ? options.showFirstName : true
      if (resident.residentNumber) {
        return `${resident.gender === 'female' ? 'Bewohnerin' : 'Bewohner'} ${resident.residentNumber}`
      } else {
        let nameString = resident.lastName
        if (salutationType === 'short') {
          nameString = `${resident.gender === 'female' ? 'Fr.' : 'Hr.'} ${nameString}`
        } else if (salutationType === 'long') {
          nameString = `${resident.gender === 'female' ? 'Frau' : 'Herr'} ${nameString}`
        }
        if (showFirstName && resident.firstName) {
          nameString = nameString + `, ${resident.firstName}`
        }
        return nameString
      }
    },
    formatProductTitle: function(options) {
      const product = options.product
      const format = options.format || 'short'
      const addSize = typeof options.addSize !== 'undefined' ? options.addSize : true

      let productString = `${product.brandName} ${product.label}`
      if (format === 'long' && product.additionalLabel) {
        productString = `${productString} ${product.additionalLabel}`
      }
      if (addSize) {
        productString = `${productString}, ${product.packageSize} ${product.packageUnit}`
      }
      return productString
    }
  }
}
