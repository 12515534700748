<template>
  <div id="app" class="app">
    <router-view />
    <div class="app__version d-print-none">v {{ version }}</div>
  </div>
</template>

<style lang="scss">
@import '@/scss/variables';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  margin: 0;
  height: 100%;
}

.app {
  height: 100%;

  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &__version {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    font-size: 0.75rem;
    color: #999;
  }
}
</style>

<script>
import { env } from '@/shared/firebase'

export default {
  data() {
    return {
      version: `${process.env.VUE_APP_VERSION} ${env === 'development' ? '(dev)' : ''}`
    }
  }
}
</script>
