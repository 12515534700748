import Vue from 'vue'
import { db, env } from '@/shared/firebase'

export default new Vue({
  data() {
    return {
      env: env === 'production' ? 'live' : 'dev'
    }
  },
  methods: {
    useDev() {
      this.env = 'dev'
    },
    useLive() {
      this.env = 'live'
    },
    enableNetwork() {
      db.enableNetwork()
      return 'network enabled'
    },
    disableNetwork() {
      db.disableNetwork()
      return 'network disabled'
    },
    useEmu() {
      this.env = 'emu'
      return 'emulator enabled'
    }
  },
  watch: {
    env() {
      if (['live', 'dev'].includes(this.env)) {
        console.log('new env', this.env)
        localStorage.setItem('useProduction', this.env === 'live' ? true : false)
        window.location.reload()
      }
      if (this.env === 'emu') {
        localStorage.setItem('useProduction', false)
        localStorage.setItem('useEmulator', true)
      } else {
        localStorage.removeItem('useEmulator')
      }
    }
  }
})
