import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/shared/firebase'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: { title: 'Login' }
  },
  {
    path: '/',
    component: () => import('@/views/_Basecamp.vue'),
    children: [
      {
        path: '',
        redirect: 'dashboard'
      },
      {
        path: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: { title: 'Dashboard' }
      },
      { path: 'organizations', component: () => import('@/views/Organizations.vue'), meta: { title: 'Organizations' } },
      {
        name: 'organization',
        path: '/organizations/:organizationId',
        component: () => import('@/views/Organization.vue'),
        meta: { title: 'Organization', meta: { title: 'Organization' } }
      },
      {
        name: 'organizationResident',
        path: '/organizations/:organizationId/residents',
        component: () => import('@/views/OrganizationResidents.vue'),
        meta: { title: "Organization's Residents" }
      },
      { path: 'orders', component: () => import('@/views/Orders.vue'), meta: { title: 'Orders' } },
      { path: 'kiosk', component: () => import('@/views/Kiosk.vue'), meta: { title: 'Kiosk' } },
      {
        path: 'materialshipments',
        component: () => import('@/views/MaterialShipments.vue'),
        meta: { title: 'Material Shipments' }
      },
      {
        path: 'orders/:orderId',
        name: 'order',
        component: () => import('@/views/Order.vue'),
        meta: { title: 'Order' }
      },
      {
        path: 'warehousecodes',
        component: () => import('@/views/WarehouseCodes.vue'),
        meta: { title: 'Warehouse Codes' }
      },
      {
        path: 'palletcodes',
        component: () => import('@/views/PalletCodes.vue'),
        meta: { title: 'Pallet Codes' }
      },
      { path: 'wagoncodes', component: () => import('@/views/WagonCodes.vue'), meta: { title: 'Wagon Codes' } },
      {
        name: 'invoices',
        path: '/invoices',
        component: () => import('@/views/Accounting/Invoices.vue'),
        meta: { title: 'Invoices' }
      },
      {
        name: 'creditnotes',
        path: '/creditnotes',
        component: () => import('@/views/Accounting/CreditNotes.vue'),
        meta: { title: 'Credit Notes' }
      },
      {
        name: 'creditnoteeditor',
        path: '/creditnotes/new',
        component: () => import('@/views/Accounting/CreditNoteEditor.vue'),
        meta: { title: 'Credit Notes' }
      },
      {
        name: 'transactions',
        path: '/transactions',
        component: () => import('@/views/Accounting/Transactions.vue'),
        meta: { title: 'Transactions' }
      },
      {
        path: 'shoppinglists',
        component: () => import('@/views/ShoppingLists.vue'),
        meta: { title: 'Shopping Lists' }
      },
      {
        name: 'products',
        path: 'products',
        component: () => import('@/views/Products.vue'),
        meta: { title: 'Products' }
      },
      {
        name: 'product',
        path: '/products/:productId',
        component: () => import('@/views/Product.vue'),
        meta: { title: 'Product' }
      },
      {
        name: 'statistics',
        path: 'statistics/:period?',
        component: () => import('@/views/Statistics.vue'),
        meta: { title: 'Statistics' }
      },
      {
        name: 'reports',
        path: 'reports',
        component: () => import('@/views/Reports.vue'),
        meta: { title: 'Reports' }
      },
      {
        name: 'warehouseactivities',
        path: 'reports/warehouseactivities/:day',
        component: () => import('@/views/WarehouseActivities.vue'),
        meta: { title: 'Warehouse Activities' }
      },
      {
        name: 'newsletters',
        path: '/newsletters',
        component: () => import('@/views/Newsletters.vue'),
        meta: { title: 'Newsletters' }
      },
      { path: 'warehouse', component: () => import('@/views/Warehouse.vue'), meta: { title: 'Warehouse' } },
      {
        path: 'print-catalog',
        component: () => import('@/views/PrintCatalog.vue'),
        meta: { title: 'Catalog' }
      },
      {
        path: 'warehousecodes',
        component: () => import('@/views/WarehouseCodes.vue'),
        meta: { title: 'Warehouse Codes' }
      },
      { path: 'wagoncodes', component: () => import('@/views/WagonCodes.vue'), meta: { title: 'Wagon Codes' } },
      {
        name: 'newsletter',
        path: '/newsletters/:newsletterId',
        component: () => import('@/views/Newsletter.vue'),
        meta: { title: 'Newsletter' }
      },
      {
        name: 'dev',
        path: '/dev',
        component: () => import('@/views/Dev.vue'),
        meta: { title: 'Dev' }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    document.documentElement.scrollTop = savedPosition?.y || 0
  }
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) next('login')
  else {
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
  }
})

export default router
