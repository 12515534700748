import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from '@/shared/firebase'
import 'bootstrap/dist/css/bootstrap.min.css'
import _ from 'lodash'
import VueCurrencyInput from 'vue-currency-input'
import HighchartsVue from 'highcharts-vue'
import VueInputAutowidth from 'vue-input-autowidth'
import { get } from '@/helpers/FirebaseHelpers'
import EmmaConsole from '@/shared/EmmaConsole'

window.emma = EmmaConsole

Vue.prototype.$get = get

Vue.set(Vue.prototype, '_', _)
Vue.use(HighchartsVue)
Vue.use(VueCurrencyInput)
Vue.use(VueInputAutowidth)

Vue.config.productionTip = false

const requireComponent = require.context('./components', false, /Base[A-Z]\w+\.(vue|js)$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = _.upperFirst(
    _.camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

import copyToClipboard from '@/shared/copyToClipboard'
Vue.mixin(copyToClipboard)

import Format from '@/shared/Format'
Vue.mixin(Format)

import TextareaAutosize from 'vue-textarea-autosize'
Vue.use(TextareaAutosize)

let appIsInitialized = false

auth.onAuthStateChanged(() => {
  if (!appIsInitialized) {
    appIsInitialized = true
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }

  if (auth.currentUser) {
    auth.currentUser.getIdTokenResult().then(idTokenResult => {
      if (idTokenResult.claims.admin) {
        store.dispatch('bindAdminAssets')
        return store.commit('setRole', 'admin')
      } else if (idTokenResult.claims.sales) {
        store.dispatch('bindSalesAssets')
        return store.commit('setRole', 'sales')
      }
    })
  } else {
    store.dispatch('unbindAssets')
    router.replace('/login')
  }
})
