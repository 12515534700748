import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { db } from '@/shared/firebase'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoaded: [],
    catalog: {},
    products: [],
    organizations: [], // possible speed improvement by removing collection from store
    demo: {},
    shelves: [], // possible speed improvement by removing collection store,
    purchase: {},
    role: null
  },
  actions: {
    bindAdminAssets: firestoreAction(({ bindFirestoreRef, commit }) => {
      bindFirestoreRef('organizations', db.collection(`organizations`)).then(() => {
        commit('setLoaded', 'organizations')
      })
      bindFirestoreRef('shelves', db.collection(`private/warehouse/shelves`)).then(() => {
        commit('setLoaded', 'shelves')
      })
      bindFirestoreRef('catalog', db.doc(`private/catalog`))
      bindFirestoreRef('products', db.collection(`private/catalog/products`)).then(() => {
        commit('setLoaded', 'products')
      })
      bindFirestoreRef('purchase', db.doc(`private/purchase`)).then(() => {
        commit('setLoaded', 'purchase')
      })
      bindFirestoreRef('demo', db.doc('website/demo'))
      return true
    }),

    bindSalesAssets: firestoreAction(({ bindFirestoreRef, commit }) => {
      bindFirestoreRef('organizations', db.collection(`organizations`)).then(() => {
        commit('setLoaded', 'organizations')
      })
      bindFirestoreRef('products', db.collection(`private/catalog/products`)).then(() => {
        commit('setLoaded', 'products')
      })
      bindFirestoreRef('shelves', db.collection(`private/warehouse/shelves`)).then(() => {
        commit('setLoaded', 'shelves')
      })
      bindFirestoreRef('purchase', db.doc(`private/purchase`)).then(() => {
        commit('setLoaded', 'purchase')
      })

      return true
    }),

    unbindAssets: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('organization')
      unbindFirestoreRef('shelves')
      unbindFirestoreRef('catalog')
      unbindFirestoreRef('products')
      unbindFirestoreRef('demo')
      return true
    })
  },
  getters: {
    isLoaded: state => {
      return dataTypes => {
        let allLoaded = true
        if (typeof dataTypes === 'string') dataTypes = [dataTypes]
        dataTypes.forEach(dataType => {
          if (state.isLoaded.indexOf(dataType) === -1) allLoaded = false
        })
        return allLoaded
      }
    },
    product: state => {
      return productId => {
        return _.find(state.products, { id: productId })
      }
    },
    organization: state => {
      return organizationId => {
        return _.find(state.organizations, { id: organizationId })
      }
    },
    stockByProductId: state => {
      return productId => {
        let spaces = {}
        let itemsInStock = []
        state.shelves.forEach(shelf => {
          Object.keys(shelf.spaces).forEach(spaceId => {
            spaces[`${shelf.id}${spaceId}`] = { items: _.cloneDeep(shelf.spaces[spaceId].items) }
          })
        })
        spaces = _.pickBy(spaces, { items: [{ productId: productId }] })
        Object.keys(spaces).forEach(spaceId => {
          spaces[spaceId].items = _.pickBy(spaces[spaceId].items, { productId: productId })
          Object.keys(spaces[spaceId].items).forEach(itemKey => {
            itemsInStock.push({ spaceId, ...spaces[spaceId].items[itemKey] })
          })
        })
        return itemsInStock
      }
    }
  },
  mutations: {
    setLoaded(state, type) {
      state.isLoaded.push(type)
    },
    setRole(state, role) {
      state.role = role
    },
    loadedState(state, options) {
      const { type, isLoaded } = options
      state[`${type}Loaded`] = isLoaded
    },
    ...vuexfireMutations
  },
  modules: {}
})
