import { db } from '@/shared/firebase'

export const get = async (path, options) => {
  const { returnId } = {
    returnId: true,
    ...options
  }

  if (path.substring(0, 1) === '/') path = path.substring(1)

  const isCollectionQuery = path.split('/').length % 2 !== 0

  if (isCollectionQuery) {
    return await db
      .collection(path)
      .get()
      .then(snapshot => {
        return snapshot.docs.map(doc => {
          const data = doc.data()
          if (returnId) data.id = doc.id
          return data
        })
      })
  } else {
    return await db
      .doc(path)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.warn(`doc ${path} does not exist`)
          return null
        }
        const data = doc.data()
        if (returnId) data.id = doc.id
        return data
      })
  }
}
