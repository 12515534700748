import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/storage'

let env = process.env.NODE_ENV
const { host } = window.location

const devHosts = ['app.dev.mitemma.de', 'shop.dev.mitemma.de', 'manage.dev.mitemma.de', 'warehouse.dev.mitemma.de']

const project = process.env.VUE_APP_PROJECT

const authDomains = {
  live: {
    app: 'app.mitemma.de',
    manage: 'manage.next.mitemma.de',
    warehouse: 'warehouse.next.mitemma.de'
  },
  dev: {
    app: 'app.dev.mitemma.de',
    manage: 'manage.next.dev.mitemma.de',
    warehouse: 'warehouse.next.dev.mitemma.de'
  }
}

if (localStorage.getItem('useProduction')) {
  env = localStorage.getItem('useProduction') === 'true' ? 'production' : 'development'
} else if (devHosts.includes(host)) {
  env = 'development'
}

let emu = false
if (localStorage.getItem('useEmulator')) {
  emu = true
}

console.info(`🚀 launching ${env}`) // eslint-disable-line
console.info(`🚀 project ${project}`) // eslint-disable-line
console.info(`🚀 authDomain ${env === 'production' ? authDomains.live[project] : authDomains.dev[project]}`) // eslint-disable-line
const config = {
  production: {
    apiKey: 'AIzaSyDiPpMNke1R4NO6lceOZrikDxhbw5O6z5M',
    authDomain: authDomains.live[project],
    databaseURL: 'https://emma-shop.firebaseio.com',
    projectId: 'emma-shop',
    storageBucket: 'emma-shop.appspot.com',
    messagingSenderId: '360155712466',
    appId: '1:360155712466:web:9b6c8329b3eba7020e960b',
    measurementId: 'G-6EPRXWP0W0'
  },
  development: {
    apiKey: 'AIzaSyAnSj0fTmxlpWMgeSXlZfkb_7RGo_3Bs1c',
    authDomain: authDomains.dev[project],
    databaseURL: 'https://emma-shop-dev.firebaseio.com',
    projectId: 'emma-shop-dev',
    storageBucket: 'emma-shop-dev.appspot.com',
    messagingSenderId: '42304600368',
    appId: '1:42304600368:web:45d42d6ff30ac784cff0f5'
  }
}

export const db = firebase.initializeApp(config[env]).firestore()

if (['app.mitemma.de', 'shop.mitemma.de'].includes(host)) {
  console.info('set settings for productive environment')
  db.settings({ experimentalForceLongPolling: true, cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED })
  firebase.analytics()
}

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

if (!['warehouse', 'manage'].includes(project)) {
  db.enablePersistence({
    synchronizeTabs: true
  })
}

export const auth = firebase.auth()
export const fb = firebase
export const storage = firebase.storage()
export const functions = fb.app().functions('europe-west3')
if (emu) {
  db.useEmulator('localhost', 8080)
  functions.useEmulator('localhost', 5001)
}
if (host.includes('localhost')) functions.useEmulator('localhost', 5001)
export { env }
